import React from 'react'
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout';
import { Link } from 'gatsby'

import cover from '../../img/curriculum/cover.jpg'
export default class ProgramIndexPage extends React.Component {

  render() {
    return (
      <Layout isProgramTypeSelected= {true}>
        <section className="section-onstage mb-mb-2">

          {/* Section 1 */}
          <Grid container
            direction="row"
            justify="center"
            className="mt-3"
          >
            <Grid item xs={12} sm={12} md={12} >
              <img src={cover} className="about-img-main" alt="On stage and beyond" />
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} >
              <h1 className="main-title mt-4 mr-2 text-right mb-mx-0 mb-mt-1 mb-text-center">Classmates to</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={6}  className="mb-px-1">
              <h1 className="main-title mb-text-center">castmates.</h1>
              <h2 className="main-sub-title mb-text-center">Join the theatre company</h2>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}

          >
            <Grid item xs={12} sm={12} md={4} >
              <Link to="/gallery">
                <button variant="contained" color="primary" className="btn btn-primary float-right btm-sm-full-width mb-mt-1">
                  View Gallery
            </button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={8} >
              <p className="page-description mb-px-1">Students are trained each year to perform at a large scale 
              theatrical performance staged at a professional theatre to a live audience, 
              the play is rehearsed over the term and performed as an end of term event.</p>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Grid container
            direction="row"
            justify="center"
            className="mt-3 mb-mt-2"

          >
            <Grid item xs={12} sm={12} md={6} >
              <h1 className="main-title mt-4 mr-2 text-right mb-mt-0 mb-px-0 mb-text-center">Take an act</h1>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <h1 className="main-title mb-text-center">break</h1>
              <h2 className="main-sub-title mb-text-center">Enrichment workshops</h2>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
            
          >
            <Grid item xs={12} sm={12} md={3} >
            </Grid>
            <Grid item xs={12} sm={12} md={9} className="mb-pt-0" >
              <p className="page-description mb-px-1">Enrichment workshops coincide with termly holidays 
              and are designed to provide children with an opportunity to collaborate 
              in open-ended ‘Team Challenges’ and quick, creative ‘Instant Challenges’ 
              to build teamwork and problem-solving abilities.</p>
            </Grid>
          </Grid>

          {/* Section 3 */}
          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-secondary onstage-card box-shadow border-radius-6"
          >
            <Grid item xs={12} sm={12} md={12} >
              <h1>Young Explorers Challenge</h1>
              <p>A project that brings younger students together to test their creativity through Team Challenges.</p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-primary onstage-card box-shadow border-radius-6"
          >
            <Grid item xs={12} sm={12} md={12} >
              <h1>Fine Arts Challenge</h1>
              <p>Students explore social issues while experimenting with
              aspects of visual arts including script writing, stage design,
              Props costume and artistic media.
              </p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-accent onstage-card box-shadow border-radius-6"
          >
            <Grid item xs={12} sm={12} md={12} >
              <h1>Improvisational Challenge</h1>
              <p>This Team Challenge focuses on spontaneity and storytelling, with teams receiving props and topics on the spot.
              </p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-primary onstage-card box-shadow border-radius-6"
          >
            <Grid item xs={12} sm={12} md={12} >
              <h1>Scientific Challenge</h1>
              <p>Scientific Challenges combine research and curiosity of science with the creativity of theatre arts.
              </p>
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="center"
            spacing={3}
            className="mt-3 bg-secondary onstage-card box-shadow border-radius-6"
          >
            <Grid item xs={12} sm={12} md={12} >
              <h1>Community Service Challenge</h1>
              <p>This project lets students identify, design, plan and execute a solution to an existing community need as a team.</p>
            </Grid>
          </Grid>
        </section>
      </Layout>
    )
  }
}
